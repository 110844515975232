<template>
  <div class="contact">
    <div class="banner">
      <el-carousel trigger="click" :interval="5000">
        <el-carousel-item>
          <img :src="require('@/assets/img/banner-2.png')" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contact_box">
      <div class="container">
        <div class="contact_title">
          <h3>联系我们</h3>
        </div>
        <!-- <div class="contact_text">
          <span>联系手机号：</span>18811596964 莫先生
        </div> -->
        <div class="contact_text"><span>邮箱：</span>hainanshunfu@163.com</div>
        <div class="contact_text">
          <span>地址：</span>
          海南省海口市龙华区滨海大道32号新外滩复兴城第3层C3024房
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.banner {
  .el-carousel__item {
    ::v-deep img {
      width: 100%;
      height: 647px;
      background-position: center 100%;
      object-fit: cover;
    }
  }
  ::v-deep .el-carousel__container {
    height: 647px;
  }
}
.contact_box {
  padding-top: 50px;
  padding-bottom: 100px;
  width: 100%;
  .contact_title {
    text-align: center;
    h3 {
      font-size: 42px;
      color: #000;
    }
  }
  .contact_text {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    color: #000;
    span {
      font-weight: bold;
    }
  }
}
</style>
